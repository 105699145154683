section.chat {
  margin-bottom: 1em;

  .messages {
    .bubble {
      background-color: #fff;
      border-radius: 0.3em;
      padding: 0.5em;
      margin: 0.3em;
      margin-bottom: 0.1em;
      box-shadow: 1px 1px 2px #aaa;
      position: relative;

      &:hover {
        .meta {
          display: block;
        }
      }

      a {
        color: #222;
        &:visited {
          color: #444;
        }
        &:hover {
          color: #000;
        }
      }

      img {
        border: none;
        width: 100%;
      }

      .meta {
        color: #666;
        position: absolute;
        top: 0;
        padding: 0.2em;
        right: 0.3em;
        display: none;
      }
    }
  }

  .meta {
    color: #333;
    font-size: 0.6em;
    text-align: right;
    padding-right: 1em;
    padding-left: 1em;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1em;
  }

  .line:first-child {
    margin-top: 0;
  }

  .line {
    margin-top: 0.3em;
  }

  &.to {
    .messages {
      .bubble {
        margin-right: 2em;
        background: #f0f0f0;

        &:only-of-type {
          border-top-left-radius: 1em;
          border-top-right-radius: 1em;
          border-bottom-right-radius: 1em;
          border-bottom-left-radius: 0;
        }

        &:first-of-type {
          border-top-left-radius: 1em;
          border-top-right-radius: 1em;
        }

        &:last-of-type {
          border-bottom-right-radius: 1em;
          border-bottom-left-radius: 0;
        }
      }
    }

    .meta {
      text-align: left;
    }
  }

  &.from {
    .messages {
      div.bubble {
        margin-left: 2em;
        background: #addfff;

        &:only-of-type {
          border-top-left-radius: 1em;
          border-top-right-radius: 1em;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 1em;
        }

        &:first-of-type {
          border-top-left-radius: 1em;
          border-top-right-radius: 1em;
        }

        &:last-of-type {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 1em;
        }
      }
    }

    .meta {
      text-align: right;
    }
  }

  &.system {
    .messages {
      div.bubble {
        background: rgb(254, 255, 173);

        &:only-of-type {
          border-top-left-radius: 1em;
          border-top-right-radius: 1em;
          border-bottom-right-radius: 1em;
          border-bottom-left-radius: 1em;
        }

        &:first-of-type {
          border-top-left-radius: 1em;
          border-top-right-radius: 1em;
        }

        &:last-of-type {
          border-bottom-right-radius: 1em;
          border-bottom-left-radius: 1em;
        }
      }
    }
  }
}
