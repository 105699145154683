body {
    padding: 0;
    margin: 0;
    background-color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

div#app {
    margin: 0;
    padding: 0;
}

.logo {
    height: 30px;
}

main {
    /*    position: absolute;
    bottom:2em;
    left:0;
    right:0;
    top:2em;
    overflow-y: scroll;
    */
    position: relative;
    flex-grow: 8;
    border-bottom: solid rgb(56, 56, 56) 1px;
    padding: .3em;
    background-color: #fff;
}

.dropZone {
    background-color: rgba(72, 121, 167, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    font-size: 60vh;
    text-align: center;
    padding-top: 20vh;
    color: rgba(255,255,255,0.8);
    &.over {
        color: rgba(255,255,255,0.8);
    }
}

p {
    padding: 0;
    margin: 0;
}

h1 {
    padding: 0;
    margin: 0;
    font-size: 1.7em;
}

video.screenshot {
    position: absolute;
    right: 100vw;
    z-index:-100;
}

div.content {
    position: absolute;
    top: 5px;
    bottom: 7px;
    left: 1px;
    right: 1px;
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-content: stretch;

    div.fullpage {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20vh;
        font-size: 2vh;
        padding: 2em;

        .icon {
            font-size: 10vh;
            text-align: center;
            padding-bottom: 2vh;
        }

        .title {
            font-size: 3vh;
        }
    }

    div.fullback {
        position: fixed;
        top: -2em;
        bottom: -2em;
        left: -2em;
        right: -2em;
        background: rgba(128, 128, 128, 0.7);
    }

    div.modal {
        border: solid 1px #ddd;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30vh;
        max-width: 350px;
        background: #fff;
        border-radius: .4em;
        box-shadow: 2px 2px 3px #888;

        div.title {
            font-weight: bold;
            padding: 0.5em;
        }

        div.message {
            padding: 0.5em;
        }

        div.buttons {
            padding: .5em 2em;
            button {
                padding: 0.5em;
                width: 100px;

                &.ok {
                    border: solid 1px #5f5;
                    background: #9f9;
                }

                &.cancel {
                    border: solid 1px #f55;
                    background: #f99;
                    float: right;
                }
            }
        }
    }
}

header {
    border-bottom: 1px solid rgb(56, 56, 56);
    height: 2em;
    padding: .3em;
    background-color: #fff;

    button.close {
        font-size: 1.6em;
        float: right;
        margin-top: -0.2em;
        font-weight: bold;
        background-color: transparent;
        border: 1px transparent solid;

        &:hover {
            color: #ddd;
        }

        &:active {
            background-color: #ccc;
        }
    }
}

@import 'Chat';

section.preamble {
    position: static;
    /*    height: 100%; */
    background-color: #fff;
    border-radius: 2em 2em 2em 2em;
    padding: 1em;
    margin: auto .3em 3em;
    box-shadow: 0px 0px 5px #aaa;

    p {
        padding-bottom: 1em;
    }

    button {
        padding-top: .3em;
        padding-bottom: .3em;
        padding-left: 1em;
        padding-right: 1em;
        background-color: #cfc;
        border-radius: 1em;
        border: 1px solid #6e6;

        &:hover {
            background-color: #aea;
            border-radius: 1em;
            border: 1px solid #4c4;
        }
    }

    table {
        textarea {
            min-width: 100%;
            max-width: 100%;
            min-height: 4em;
            resize: vertical;
        }

        td.tableheader {
            font-weight: bold;
            padding-top: .5em;
        }
    }
}


p.showqueue {
    font-size: 1.2em;
    padding: 1em;
    text-align: center;
}

div.chatcontainer {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
}

div.chatcontainerwrapper {
    /*    position: relative;
    height: 100%; */
    overflow-y: scroll;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
}

footer {
    vertical-align: top;
    position: relative;
}

div#messagecontainer {
    margin-right: 101px;
    border: solid #ccc 1px;
}

div#message {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    min-height: 2.5em;
    border: none;
    max-height: 10em;
    overflow-y: auto;
    outline: none;
    -webkit-appearance: none;
}

div#sendmessagecontainer {
    width: 100px;
    float: right;
    padding-top: 0;
    position: relative;

    .uploadlist {
        position: absolute;
        bottom: 2.6em;
        right: 0px;
        width: 9em;

        button {
            display: block;
            width: 100%;
            text-align: left;
        }
    }

    button {
        font-size: 1em;
        display: inline-block;
        padding-top: .68em;
        padding-bottom: .68em;
        margin: 0;
        background-color: #eee;
        border: solid #ccc 1px;
        outline: none;
        -webkit-appearance: none;
        
        &:active {
            background-color: #ccc;
            border: solid #ccc 1px;
        }

        &#sendmessage {
            width: 60%;
        }

        &#sendattachment {
            width: 40%;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;

            &.attachmentButtonSelected {
                background-color: rgb(156, 156, 156);
            }

            .addpadding {
                padding-top: .75em;
            }

            .arrowup {
                vertical-align: super;
                font-size: .7em;
            }
        }
    }
}

section#status {
    padding: 1px;
    padding-right: 4px;
    text-align: right;
    font-size: .9em;
    background-color: #eee;
    color: #000;
}